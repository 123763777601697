<template>
  <div>
    <p><span>$100</span> event treatment this summer! <span>$20</span> off!<span class="black">*</span></p>
    <p>Receive a <span>FREE</span> application when you successfully refer someone!</p>
    <h5>*Valid until July 7th</h5>
</div>
</template>

<script>
export default {
  name: "promotion-banner"
}
</script>

<style scoped>
div {
  background: rgb(255, 247, 0);
  padding: 10px;
  /* border-radius: 20px 20px 0px 0px; */
  box-shadow: inset 4px 4px 15px 0px #000000, inset 5px 5px 15px 5px rgba(0,0,0,0);
}
p {
  font-size: 2rem;
  color: rgb(255, 0, 0);
  text-align: center;
}
span {
  color: rgb(37, 185, 37);
}
.black {
  /* dumb and hacky */
  color: black; 
}

@media (max-width: 1000px) {
  p {
    font-size: 1.5rem;
  }
}
</style>