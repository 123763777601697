<template>
    <div class="about-us">
        <img class="avatar" src="../../public/assets/about/lance.png" />
        <div class="about-text">
            <div class="about-title">
                <h1>Lance</h1>
                <h3><i>Owner/Operator</i></h3>
            </div>
            <div class="about-bio">
                <p>
                    Hello there, my name is Lance and I've been in the outdoor pest control industry for several years now. Throughout my career I've noticed a lack of consistency and quality from other companies. I've decided to take matters into my own hands and provide a treatment program that truly delivers.
                </p>
                <br />
                <p>
                    It's surprising to me how many families I meet that avoid going outside because they are afraid of getting bitten! After treatment, my customers tell me they completely forgot what it was even like to have mosquitoes at their house. Seeing the smiles on their faces now that they can enjoy their home again is the best part of my job.
                </p>
                <!-- <p>
                    Hello! I've been in the outdoor pest control industry for several years now, and I've witnessed firsthand the shortcomings of different companies. I realized they were missing something crucial: consistency and quality. I decided to take matters into my own hands and provide a service that truly delivers. It's surprising how many people still avoid their own backyard due to constant bug bites, and I provide the solution they are looking for.  My customers have shared stories with me of how they can't even remember there were mosquitoes in their yard in the first place. The smiles from every family I serve make it all worthwhile for me.
                </p> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'about-us'
}
</script>

<style>
.about-us {
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    justify-content: space-evenly;
}
.avatar {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: groove 5px rgba(0, 255, 4, 0.325);
    /* filter: brightness(115%) saturate(122%) contrast(97%);
    -webkit-filter: brightness(115%) saturate(122%) contrast(97%);
    -moz-filter: brightness(115%) saturate(122%) contrast(97%); */
    border-radius: 50%;
        width: 25%;
        height: 25%;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 10px;
}
.about-text {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.4rem;
}
.about-title {
    padding-bottom: 1rem;
}

@media (min-width: 2100px) {
    .avatar {
        width:  20%;
        height: 20%;
    }
}
@media (min-width: 1700px) {
    .about-text{
        font-size: 1.5rem;
    }
}
@media (max-width: 1200px) {
    .about-text{
        font-size: 1rem;
    }
}
@media (max-width: 900px) {
    .about-text{
        width: 100%;
    }
    .avatar {
        width:  65%;
        height: 65%;
        margin-left: auto;
        margin-right: auto;
    }
    .about-us {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
        display: flex;
        flex-direction: column;
    }
    .about-title {
        text-align: center;
        padding-top: 1em;
    }
    .about-bio{
        text-align: justify;
    }
}
</style>