
<template>
    <div id="home">
      <div class="home-container">
        <div class="home-content">
           <h1 class="home-content-title">You Bug Us.</h1>
           <h1 class="home-content-title">They Bug Off.</h1>
        </div>
      </div>
      <div class="landing-tiles">
        <tile-image class="land-circle" 
        v-for="circle in landingCircles" :key="circle.id"
        :msg="circle.message" :title="circle.title" :imgURI="circle.imgURI"
        />
      </div>
    </div>
</template>

<script>
import TileImage from '@/components/TileImage.vue';
export default {
  name: 'home-page',
  components: { TileImage },
  data() {
      return {
        landingCircles: [
          {
            id: 0,
            title: "Pet Safe, People Safe",
            message: "All of my applications are safe for you, your family and your pets! I am certified through the Pennsylvania Department of Agriculture, I know where to spray and what to spray. I only use EPA registered products that provide the safest, and most effective treatments.",
            imgURI: "circles/dogplaySVG.png"
          },
          {
            id: 1,
            title: "Independently Owned",
            message: "This is not a franchise! My name is Lance, and I am an independent business owner based in Chester County, PA. I go above and beyond to provide exceptional service. I do better than the big companies by investing in the best products, training, and service. I treat your home as if it was my home, with the utmost care and detail.",
            imgURI: "circles/talkingSVG.png"
          },
          {
            id: 2,
            title: "Bite-Free Living",
            message: "Once you are signed up, your property will receive prompt treatment, often scheduled for the same week. After being added to the service schedule, you will receive a notification the night before each treatment. The treatments are typically scheduled every 21-28 days, ensuring you no longer have to stress about bites!",
            imgURI: "circles/chillSVG.png"
          }
        ]
      }
  }
};
</script>

<style scoped>
#home {
  font-family: 'Sora', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-container {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 40rem;
  background-image: url('../../public/assets/background-photo.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 68%;
  z-index: -1;
  border-radius: 0;
}
.landing-tiles {
  padding-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-evenly;
}
.land-circle {
  width: 25rem;
}
.home-content {
  padding-right: 35%;
  padding-top: 0%;
}
.home-content-title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.808);
  color: rgb(255, 255, 255);
  text-align: center;
  font-family: 'Comfortaa', sans-serif;
  font-size: 4em;
  margin: .3em;
  /* align-self: center; */
}

@media (min-width: 2000px) {
  .home-container {
    width: 100%;
    height: 60rem;
  }
}
@media (max-width: 1060px) {
  .home-content-title {
    padding-right: 0%;
  }
  .home-container {
    flex-direction: column;
    justify-content: center;
    background-position-x: 40%;
  }
  .land-circle {
    margin-bottom: 5rem;
    width: 90%;
  }
}
@media (max-width: 600px) {
  .home-container {
    justify-content: flex-start;
    background-image: url('../../public/assets/background-photo-mobile.jpg');
  }
  .home-content {
    padding: 0;
    padding-top: 3%;
  }
  .home-content-title {
    font-size: 3em;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    color: rgb(255, 255, 255);
  }
}
</style>
