<template>
  <div class="total-nav-container">
    <div class="social-media">
      <SocialMedia></SocialMedia>
    </div>
    <div id="nav">
      <div class="nav-container">
        <div
          v-for="menuItem in menuItems"
          :key="menuItem.route"
          :class="{
            'nav-item': true,
            'active-nav-item': isActiveNavItem(menuItem),
          }"
          @click="goToMenuItem(menuItem)"
        >
          <span v-html="renderIcon(menuItem.icon)"></span>
          <span class="break-spaces">&nbsp;&nbsp;</span>
          <span class="menu-text">{{ menuItem.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialMedia from './SocialMedia.vue';

export default {
  name: "nav-bar",
  data() {
    return {
      menuItems: [
        { text: "Home", route: "home-page", icon: "fa-house" },
        { text: "FAQ", route: "frequently-asked", icon: "fa-question" },
        { text: "Services", route: "our-services", icon: "fa-mosquito" },
        { text: "About", route: "about-us", icon: "fa-book-open" }
      ],
    };
  },
  computed: {
    curRoute() {
      return this.$route.name;
    },
  },
  methods: {
    goToMenuItem(menuItem) {
      this.$router.push({ name: menuItem.route });
    },
    renderIcon(iconClass) {
      return `<i class="fas ${iconClass}">  </i>`;
    },
    isActiveNavItem(menuItem) {
      return this.curRoute === menuItem.route;
    },
  },
  components: { SocialMedia },
};
</script>

<style>
.total-nav-container {
}

#nav {
  font-family: 'Sora', sans-serif;
  position: sticky;
  font-size: 20px;
  top: 0;
}
.nav-container {
  display: flex;
  justify-content: space-evenly;
  height: 88px;
  /* border-radius: 25px; */
  margin-left: 0;
  margin-right: 0;
  /* background: radial-gradient(circle at right, #c1ffd5, #3ba560, #001d0a 100%); */
  /* box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2); */
  transition: 2s; /* turns font color back to white gradually*/
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23%; /* keeps text locked in place */
  cursor: pointer;
  white-space: nowrap;
}

.active-nav-item {
  z-index: 0;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.14) inset, 
  0px 1px 10px 0px rgba(0,0,0,0.12) inset, 
  0px 2px 13px -1px rgba(0,0,0,0.2) inset;
  font-size: 24px;
  transition: all .2s;
  color: rgb(0, 0, 0);
}

/* mobile styles */
@media (max-width: 1280px) {
  .total-nav-container {
    margin-right: 0px;
    margin-left:  0px;
    flex-direction: column;
  }

  .social-media {
    order: 2;
  }

  #nav {
    order: 1;
  }
}
@media (max-width: 760px) {
  .nav-container div {
    display: flex;
    flex-direction: column;
  }
  .nav-item {
    transition: 0s;
    font-size: 14px;
  }
  .active-nav-item {
    font-size: 16px;
  }
  .menu-text {
    order: 2;
  }
  .break-spaces {
    display: none;
  }
}
</style>