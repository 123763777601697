<template>
  <div id="app">
    <nav-bar class="nav-bar"/>
    <promotion-banner></promotion-banner>
    <div class="view-shadow">
      <transition name="fade">
          <router-view/>
      </transition>
    </div>
    <im-interested class="im-interested"/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'
import ImInterested from './components/ImInterested.vue'
import PromotionBanner from './components/PromotionBanner.vue';

export default {
  components: {
    NavBar, 
    ImInterested,
    PromotionBanner
  }
}
</script>

<style>
/* imported fonts */
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&display=swap');

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity .4s ease-in-out;
}

.view-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding-bottom: 50px;
}

.nav-bar {
  margin-bottom: 5px;
}
#app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Sora', sans-serif;
  -webkit-font-smoothing: antialiased;
  color: black;
}
body {
  margin-top: 1rem;
  background-color:#faf9f6;
  /* background-image: url('../public/assets/background-photo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%; */
}
* { 
  -webkit-tap-highlight-color: transparent;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glass-background {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.439);
}

@media (max-width: 320px) {
  #app {
    margin-left: auto;
  }
}
</style>