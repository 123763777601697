<template>
    <a href="https://buy.stripe.com/8wMdRC8N47TH2ti8wy">Customer Portal</a>
</template>

<script>

export default {
    name: 'customer-portal'
    // this.$router.push("https://buy.stripe.com/8wMdRC8N47TH2ti8wy");
}
</script>

<style scoped>
    
</style>
