import { createStore } from 'vuex'

const store = createStore({
  state: {
    homeArticle: "",
    services: [ //This could avoided being hardcoded with an actual backend
    {
      serviceId: 0,
      serviceName: "Mosquito Barrier",
      color: "#ff1c27",
      color2: "#7f0e13",
      desc: "The Mosquito Barrier treatment reduces mosquito populations on your property, then leaves a weather-resistant residual that lasts 28 days to keep them away. My main goal when treating mosquitos is to stop the lifecycle on your property. Mosquitoes lay their eggs in standing water. Think buckets, wheelbarrows, old tires, gutters, or areas of your lawn that hold water after it rains. Over 300 eggs can fit into a body of water as small as a water bottle cap. This application includes a free mosquito larvicide to treat these breeding sources and will halt the lifecycle of mosquitoes around your home.",
      img: "assets/services/mosquito.png",
      imgInfo: "",
      infoImgUri:"assets/services/mosquito2.jpg",
      targets: ["Mosquitoes"],
      helps: ["Beetles", "Stink Bugs"],
      price: 80,
      treatments: 9
    },{
      serviceId: 1,
      serviceName: "Flea & Tick Barrier",
      color: "#00f51e",
      color2: "#007a0f",
      desc: "The Flea & Tick Barrier treatment reduces flea and tick populations on your property, then leaves a 28 day weather-resistant residual to keep them away. The Flea & Tick application is applied to the grassy turf of your property where fleas and ticks like to hang out. As with all of my applications, the main goal is to halt the lifecycle on your lawn. This application includes a treatment that will stop fleas and ticks from maturing into adulthood, further protecting your home.",
      img: "assets/services/tick.png",
      imgInfo: "Applications are every 21-28 days dependent on weather conditions. Prices start at $80 a treatment for properties .5 acres or less.",
      infoImgUri:"assets/services/tick2.jpg",
      targets: ["Ticks", "Fleas"],
      helps: ["Chinch Bugs", "Earwigs"],
      price: 80,
      treatments: 9
    },{
      serviceId: 2,
      serviceName: "Event Treatment",
      color: "#1c67ff",
      color2: "#0e337f",
      desc: "Planning a graduation party? Are you hosting Fourth of July this year? This specialty treatment is designed to significantly reduce many pests for your party including but not limited to mosquitoes, fleas and ticks. This treatment will be scheduled 3-7 days before your event for the best possible results. If you had a good time at your event, please contact me if you would like to continue your service throughout the rest of the summer!",
      img: "assets/services/event.png", //"https://drive.google.com/uc?id=1xVWYoLAIgNGn_PHcRODGa4Kl5l_H_2gd"
      imgInfo: "treats for all bugs, hardcore knockdowns",
      infoImgUri: "assets/services/event2.jpg",
      targets: ["Mosquitoes", "Ticks", "Fleas"],
      helps: ["Chinch Bugs", "Earwigs", "Beetles", "and more!"],
      price: 120
    },{
      serviceId: 3,
      serviceName: "Premium Barrier",
      color: "#7b26dc",
      color2: "#3d136e",
      desc: "The Premium Barrier spray is the combined Mosquito Barrier and Flea & Tick Barrier at a cheaper price, saving you money while keeping your home protected! This weather-resistant treatment is applied every 21-28 days. Having both barrier applications applied to your home at the same time increases the effectiveness of both applications. This application is designed to halt the lifecycle of all these pests, providing long-lasting protection throughout the entire summer. Please consider our prepay discount for this program!",
      img: "assets/services/premium.png",
      imgInfo: "lifecycle info",
      infoImgUri: "assets/services/premium2.jpg",
      targets: ["Mosquitoes", "Ticks", "Fleas"],
      helps: ["Chinch Bugs", "Earwigs", "Beetles", "Stinkbugs", "and more!"],
      price: 110,
      treatments: 9
    }/*,{
      serviceId: 4,
      serviceName: "Spotted Lanternfly Spray",
      color: "#ff8624",
      color2: "#7f4312",
      desc: "lanternfly descriptions",
      img: "assets/services/lanternfly.png",
      imgInfo: "lifecycle info",
      infoImgUri: "assets/services/lanternfly2.jpg"
    }*/
    ]
  },
  mutations: {
    setHomeArticle(state, content) {
      state.homeArticle = content;
    }
  },
  actions: {
    async fetchHomeArticle({ commit }) {
      try {
        const response = await '../assets/articles/home.txt';
        const content = await response.text();
        commit('setHomeArticle', content);
      } catch (error) {
        console.error('Error fetching home article:', error);
      }
    }
  },
  getters: {
    getHomeArticle(state) {
        return state.homeArticle;
    },
    getServices(state) {
        return state.services;
    }
  }
})

export default store