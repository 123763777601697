<template>
    <div class="faq-container">
        <div class="question-container">
            <div class="question-frame question-background" v-for="question in questionsList" :key="question.id" :class="isEven(question.id) ? 'left-image' : 'right-image'">
                <span v-if="question.imgURI" class="img-container" :class="isEven(question.id) ? 'left-image' : 'right-image'" >
                    <img :src="require(`../../public/assets/faqpics/${question.imgURI}`)" class="faq-image" rel="preload"/>
                </span>
                <div class="ques">
                    <h2 class="question-text">{{ question.q }} </h2>
                </div>
                <div class="ans">
                   <div class="answer-text">{{ question.a }}</div> 
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'home-page',
  computed: {
    questionsList() {
        return this.questions
    },
    isEven() {
      return function(num) {
        return num % 2 == 0;
      }
    }
  },
  data() {
    return {
        questions: [
            {
                id: 0,
                q: "What do you do?",
                a: "At Near Me Pest Services LLC, I offer a range of spray barrier treatments for outdoor pest control. My focus is in mosquitoes and fleas & ticks. Also, please reach out for any specialty treatment and I can discuss a solution for you. I've got you covered for all your pest control needs!",
                imgURI: "whatWeDo.jpg"
            },
            {
                id: 1,
                q: "How does it work?",
                a: "My treatments are designed to disrupt the life cycle of the target pest, reducing their development and preventing them from coming back on your property. Most treatments are scheduled every 21-28 days. These are proactive treatments designed to stop the bugs in their tracks, ensuring you have a pest-free space to enjoy.",
                imgURI: "techTalking.jpg"
            },
            {
                id: 2,
                q: "Is it safe for my kids and pets?",
                a: "Absolutely! The safety of your loved ones, including children and pets, is my top priority. During the treatment, please keep both kids and furry friends indoors. Once the treatment is complete, simply wait 15-30 minutes for the treatment to dry before returning outside. The products used are 100% safe and approved by the Pennsylvania Department of Agriculture. Many of my customers with dogs have experienced the effectiveness of the flea & tick barrier treatment in keeping their pets protected.",
                imgURI: "dog.jpg"

            },
            {
                id: 3,
                q: "It's going to rain, won't the treatment wash away?",
                a: "No worries! I use a high-quality sticker agent to ensure the treatment remains effective even in rainy conditions. However, I will never spray if a treatment would be ineffective. The applications are designed to withstand various weather conditions, providing long-lasting protection for your property.",
                imgURI: "stormCloud.jpg"
            },
            {
                id: 4,
                q: "I'm still getting bit, what gives?",
                a: "If you're experiencing bites after treatment, don't worry! You may be due for a follow-up treatment very soon, as applications are scheduled every 21-28 days. My aim is to ensure you never go a weekend getting bites. In the extremely rare occurrence that the treatment doesn't meet your expectations, I provide a quality guarantee. I will conduct a respray at no cost to you.",
                imgURI: "mosSwat.jpg"
            }
        ]
    }
  }
}
</script>
<style>

.faq-container {
    margin-top: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.question-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.faq-image {
    display: block;
    height: 15em;
    width:  15em;
    object-fit: cover;
    background-clip: content-box;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.question-frame {  
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.14) inset, 
    0px 1px 10px 0px rgba(0,0,0,0.12) inset, 
    0px 2px 13px -1px rgba(0,0,0,0.2) inset;
    background-clip: content-box;
    display: grid;
    grid-template-areas: 
    "iLeft ques iRight"
    "iLeft ans iRight";
	background-size: cover;
	color: rgb(0, 0, 0);
    height: fit-content;
    margin-bottom: 25px;
}
.ques {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    z-index: 1;
    grid-area: ques;
}
.ans {
    box-sizing: border-box;
    z-index: 1;
    grid-area: ans;
}
.left-image {
    grid-area: iLeft;
    border-top-right-radius:    20%;
    border-bottom-right-radius: 20%;
    border-top-left-radius:    100px;
    border-bottom-left-radius: 100px;
}
.left-image img {
    border-right: 0;
    /* box-shadow: rgba(0, 0, 0, 0.369) -10px 0px 30px 0px; */
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
}
.right-image {
    grid-area: iRight;
    border-top-left-radius:    20%;
    border-bottom-left-radius: 20%;
    border-top-right-radius:    100px;
    border-bottom-right-radius: 100px;
}
.right-image img {
    border-left: 0;
    /* box-shadow: rgba(0, 0, 0, 0.369) 10px 0px 30px 0px; */
    border-top-right-radius: 20%;
    border-bottom-right-radius: 20%;
}
.question-text, .answer-text {
    font-family: 'Sora', sans-serif;
}
.question-text {
    font-size: 1.75rem;
    text-align: center;
    align-self: flex-end;
}
.answer-text {
    font-size: 1.10rem;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

@media (min-width: 2000px) {
    .faq-container {
        width: 60%;
    }
}
@media (max-width: 1150px) {
    .left-image {
        border-radius: 0;
    }
    .right-image {
        border-radius: 0;
    }
    .left-image img {
        border-radius: 0;
    }
    .right-image img {
        border-radius: 0;
    }
    .faq-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 900px) {
    .answer-text {
        font-size: 14px;
        width: 80%;
    }
}
@media (max-width: 710px) {
    .faq-image {
        z-index: 0;
        /* -webkit-filter: blur(3px) brightness(76%) contrast(172%) saturate(70%);
        filter: blur(3px) brightness(76%) contrast(100%) saturate(70%); */
        width: 100%;
    }
    .answer-text {
        text-align: center;
        font-size: 14px;
    }
    .question-text {
        padding-top: .5em;
        font-size: 20px;
        margin-top: auto;
        margin-bottom: 0;
    }
    .question-frame {
        padding-bottom: 1em;
        box-shadow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        margin-bottom: 0;
        
    }
    .question-background {
        height: 100%;

    }
}
</style>