<template>
  <div class="interest-background">
    <div class="glass-background">
      <div id="im-interested" :style="isSubmitted(submitted)">
        <form id="quote-form" novalidate="novalidate" v-if="!submitted">
          <h1 class="form-title">Request a quote!</h1>
          <div class="section-form-container">
            <label for="name" :class="{ 'red-icon': isCheckingForEmpty && isFieldEmpty('name') }"><i class="fa-solid fa-user"></i></label>
            <input 
              type="text" id="name" placeholder="Your Name"
              v-model="quote.name" autocomplete="name"
            >
            <label for="address" :class="{ 'red-icon': isCheckingForEmpty && isFieldEmpty('address') }"><i class="fa-solid fa-location-dot"></i></label>
            <input 
              type="text" id="address" placeholder="Address" ref="address"
              v-model="quote.address"
            />
            <label for="email" :class="{ 'red-icon': isCheckingForEmpty && isFieldEmpty('email') }"><i class="fa-solid fa-envelope"></i></label>
            <input 
              type="text" id="email" placeholder="your.email@email.com"
              v-model="quote.email" autocomplete="email"
            />
            <label for="phone" :class="{ 'red-icon': isCheckingForEmpty && isFieldEmpty('phone') }"><i class="fa-solid fa-mobile-screen"></i></label>
            <input 
              type="number" id="phone" placeholder="Phone Number"
              v-model="quote.phone" autocomplete="tel-national"
            />
          </div>
          <div class="service-container-container">
            <h2 :class="{ 'red-icon': isCheckingForEmpty && isFieldEmpty('services') }" class="form-title">Please select interested services</h2>
            <div class="quote-service-container" onselectstart="return false">
              <span v-for="service in services" :key="service.serviceId">
                <img :src="service.img" class="service-choice" :id="service.serviceName" :style="getStyle(service)"
                      @click="addServiceToForm(service)" />
              </span>
            </div>
          </div>
        
          <textarea id="message" placeholder="Questions or Comments"
                    v-model="quote.message"></textarea>
        
          <button id="submit-form" type='button' class="button-submit"
                  @click="sendQuote(quote)">
            <h1>Submit</h1>
          </button>
        </form>
        <div v-else>
          <h1 class="form-title">Thank you for your interest! In the meantime, please check out our <a target="_blank" href="https://www.facebook.com/NearMePest/">Facebook Page</a>!</h1>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import GoogleService from '@/services/GoogleService'
export default {
  name: "im-interested",
  data() {
    return {
        emptyQuote: { //Used to repopulate quote. so the page doesn't need to reload after submitting
          name: "",
          address: "",
          email: "",
          phone: "",
          services: "",
          message: ""
        },
        quote: {
          name: "",
          address: "",
          email: "",
          phone: "",
          services: "",
          message: ""
        },
        clickedServices: {}, // Object to track clicked state of services
        submitted: false, //track if submitted message appears
        isCheckingForEmpty: false //set to true after submit is pressed in sendQuote()
      }
  },
  mounted() {
    const input = this.$refs.address;
    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(input);
    
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place && place.address_components) {
        //filters out country instead of using place.formatted_address
        const formatAddress = place.address_components
          .filter(component => component.types.includes('route') || 
          component.types.includes('street_number') || 
          component.types.includes('administrative_area_level_1') ||
          component.types.includes('locality') || 
          component.types.includes('postal_code'))
          .map(component => component.short_name)
          .join(' ');
      
        this.quote.address = formatAddress;
      }
    });
  },
  computed: {
    services() {
      return this.$store.getters.getServices;
    },
    getStyle() {
      return function (service) {
        if (this.quoteHasService(service.serviceName)) {
          return {
            backgroundColor: service.color,
            border: ".2rem solid",
            borderColor: service.color2,
            height: "4rem"
            };
        } else {
          return {
            backgroundColor: service.color2
          };
        }
      }
    },
    isFieldEmpty() {
      return function(fieldName) {
        if (this.quote) {//TODO get this to properly be computed during deployment
          return this.quote[fieldName] === '';
        }
      }.bind(this); //Should help keep everything working when deployed on firebase. Was not realizing this.quote when deployed
    },
    isSubmitted() {
      return function(submitted) {
        if(submitted) {
          return {
            backgroundColor: "rgba(150, 255, 100, 0.393)",
            content: "Thank you! You will be contacted shortly."
          }
        }
      }
    }
  },
  methods: {
    quoteHasService(serviceName) {
      return this.quote.services.includes(serviceName)
    },
    handleServiceClick(index) {
      this.clickedServices[index] = !this.clickedServices[index];
    },
    isValidForm(form) {
      var notEmpty = true;
      Object.keys(form).forEach(function(key) {
        if(form[key] == "" && key != "message") { //hardcoded whoops
          if(notEmpty) {
            notEmpty = false;
            return;//return false; was not working for some reason
          }
        }
      })
      return notEmpty;
    },
    sendQuote(form) {
      this.isCheckingForEmpty = true;
      // alert(JSON.stringify(this.quote));

      if(this.isValidForm(form)) {
        GoogleService.methods.postTheForm(form);
        this.submitted = true;
        this.isCheckingForEmpty = false;
      }
      if(!this.isCheckingForEmpty) {
        //Had some issues in production here. Was throwing a runtime error when submitting with incomplete data
        this.quote = this.emptyQuote;
      }
    },
    addServiceToForm(service) {
      //const elem = document.getElementById(service.serviceName);

      if(!this.quote.services.includes(service.serviceName)) { //Generating a string list to post later
        //elem.style.setProperty("background", service.color2); //change BG color to secondary color
        // elem.style.setProperty("color", "#FFF");

        this.quote.services += (", " + service.serviceName);//adds ", serviceName"

      } else if (this.quote.services.includes(service.serviceName) ) { //if it DID contain it, we're going to remove it
        //elem.style.setProperty("background", service.color); //change it back to normal when deselected
        // elem.style.setProperty("color", "#000");

        //TODO change all hardcoded colors to variables in #app
        this.quote.services = this.quote.services.replace(', ' + service.serviceName, '') //replaces instance with ""
      }
    }
  }
}
</script>

<style>
.interest-background {  
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-image: url('../../public/assets/background-photo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
#im-interested {
  max-width: fit-content;
  padding: 15px;
  margin: auto;
  transition: .2s;
}
#quote-form {
  /* display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; */
  display: grid;
  grid-template-areas: 
    "title title title"
    "form service service"
    "message message message"
    "submit submit submit";
}
/* label hack, getting position locked in */
label {
  position: relative;
}
label i {
  filter: drop-shadow(
    1px 1px 3px rgba(33, 54, 96, 0.73)
  );
  position: absolute;
  font-size: 18px;
  left: -5px;
  top: -5px;
}
.red-icon {
  font-size: 30px;
  color: #d40000;
}

.form-title {
  text-align: center;
}
h1 {
  grid-area: title;
}
#message{
  grid-area: message;
  resize: none;
  width: 100%;
  height: 150px;
  margin-top: 5px;
  font-size: 24px;
}

#submit-form {
  grid-area: submit;
}
.section-form-container {
  grid-area: form;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.section-form-container input {
  padding: 12px 20px;
  margin-bottom: 10px;
  font-size: 26px;
  width: 40rem;
}

input, textarea {
  background:  #f8f8f8;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  border-radius: 10px;
  border: none;
  border-bottom: 2px  rgb(0, 0, 0);
}

input:focus, textarea:focus {
  outline:none;
  transition: .1s;
  border-bottom: 6px solid rgb(0, 0, 0);
  box-shadow: none;
}
.service-container-container {
  max-width: 40rem;
  grid-area: service;
}
.quote-service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}
.quote-service-container span {
  display: flex;
  align-items: center;
}

.service-choice {
  cursor: pointer;
  border-radius: 15px;
  height: 4.5rem;
  margin: 2px;
  transition: .1s;
  box-shadow: 8.0px 16.0px 16.0px hsl(0deg 0% 0% / 0.25);
  /* box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;       */
}

/* button */
.button-submit {
  margin-top: 5px;
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-submit:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

/* mobile styles */
@media (max-width: 1280px) {
}
@media (max-width: 960px) {
  #im-interested {
    margin: auto;
    box-shadow: none;
  }
  .section-form-container input {
    padding: 12px 20px;
    width: inherit;
    margin-bottom: 10px;
    font-size: 20px;
  }
  #quote-form {
    display: flex;
    flex-direction: column;
  }
}
</style>

