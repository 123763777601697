// import { google } from 'googleapis'; //npmjs package to make crud requests easier


// const credentials = {
//   client_email: process.env.VUE_APP_GOOGLE_CLIENT_EMAIL,
//   private_key: process.env.VUE_APP_GOOGLE_PRIVATE_KEY
// };

// const scopes = ['https://www.googleapis.com/auth/forms'];

// const auth = new google.auth.GoogleAuth({
//   credentials,
//   scopes,
// });

// const authClient = await auth.getClient();
// const formClient = google.forms({
//     version: 'v1',
//     auth: authClient
// });

// export default {
//     async getForms() {
//         return await formClient.forms.list();
//     }
// }



import axios from "axios";

/*
HERE IT IS - The horrible, hacky way to just automatically
fill out the google form without a backend. Basically a backwards webcrawler

The only reason I went for this solution and not a backend solution was to
save on hosting costs. Capturing user data will be more important
if I add a payment portal, etc. That will require a real backend in Spring/Django
*/
const ACTION_URL =
    "https://docs.google.com/forms/d/e/1FAIpQLSdxLQAvLIRfJNhgcjLUJJ79pCwWp4VXqc_YKBy6N18PB-6HUw/formResponse";
const nameId = "entry.1220734728";
const addressId = "entry.392228228";
const emailId = "entry.923829882";
const phoneId = "entry.1181961207";
const servicesId = "entry.176431260";
const messageId = "entry.325142964";
const CORS_proxy = "https://us-central1-near-me-pest-website.cloudfunctions.net/cors?url="; //CORS origin. Should work forever from any device

export default {
    methods: {
        async postTheForm(form) {
            //alert(JSON.stringify(form));
            const formData = new FormData();
            formData.append(nameId, form.name);
            formData.append(addressId, form.address);
            formData.append(emailId, form.email);
            formData.append(phoneId, form.phone);
            formData.append(servicesId, form.services); //checkboxes on google, not sure if itll work
            formData.append(messageId, form.message);
            axios.post(CORS_proxy + ACTION_URL, formData)
            .catch(function (error) {
                alert("There was an error. Please contact info@nearmepest.com with this information\n___________________________________________________________"
                    + JSON.stringify(error));
            });
        }
    }
};