import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import OurServices from '../views/OurServices.vue'
import FrequentlyAsked from '../views/FrequentlyAsked.vue'
import AboutUs from '../views/AboutUs.vue'
import CustomerPortal from '../views/CustomerPortal.vue'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home-page',
            component: HomePage
        },
        {
            path: '/services',
            name: 'our-services',
            component: OurServices
        },
        {
            path: '/faq',
            name: 'frequently-asked',
            component: FrequentlyAsked
        },
        {
            path: '/about',
            name: 'about-us',
            component: AboutUs
        },
        {
            path: "/customer",
            name: "customer-portal",
            component: CustomerPortal,
            beforeEnter: () => {
                window.location.href = 'https://billing.stripe.com/p/login/6oEdTgbAF55m6fS3cc';
                // window.location.href = 'https://buy.stripe.com/4gwcNyaVc8XL3xm7sx';
            }
        }
    ]
})

export default router;