<template>
    <div class="social-media-container">
        <div class="brand-icon-container">
            <div
              v-for="brandIcon in brandIcons"
              :key="brandIcon.text"
              :class="{
                'brand-icon': true,
              }"
            >
                &nbsp;<span v-html="renderBrandIcon(brandIcon)"></span>&nbsp;
            </div>
        </div>
        <div id="logo">
            <a href="/"><img src="../../public/assets/logo.png"></a> 
        </div>
        <div class="phone-num number">
            <a target="_blank" href="tel:4842371941">(484) 237 - 1941</a>
        </div>
        <div class="phone-num quote" @click="goToInterested" :style="{ backgroundColor: '#21F' }">
            <a>Request a quote!</a>
        </div>
    </div>
</template>

<script>
export default {
    name: "social-media",
    data() {
        return {
            brandIcons: [
                { text: "facebook", icon: "fa-brands fa-facebook", color: "#005eff", link: "https://www.facebook.com/NearMePest/"}
                //there is no nextdoor awesome font. Not so awesome now, huh?
                // { text: "nextdoor", icon: "fa-solid fa-circle", color: "#76d40e", link: "https://nextdoor.com/pages/near-me-pest-services-llc-kennett-square-pa/"},
                // ,{ text: "google", icon: "fa-brands fa-google", color: "#ff0000", link: "https://www.google.com/search?q=near+me+pest+services+llc"},
                // { text: "twitter", icon: "fa-brands fa-twitter", color: "#80aeff", link: "https://twitter.com/nearmepest"}
            ],
        };
    },
    methods: {
        renderBrandIcon(iconClass) {
            return `<a target="_blank" href="${iconClass.link}" class="${iconClass.icon}" style="color: ${iconClass.color}"></a>`;
        },
        goToInterested() {
            const element = document.getElementById("im-interested");
            element.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }
};
</script>

<style>
.social-media-container {
    display: flex;
    justify-content: space-around;
    max-height: 10em;
}
#logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#logo img {
    width: 200px;
    filter: drop-shadow(
      1px 2px 4px rgba(11, 20, 38, 0.61)
    );
}

.brand-icon-container {
    display: flex;
    align-items: center;
}
.brand-icon a {
    text-decoration: none;
}

.brand-icon {
    font-size: 60px;
    filter: drop-shadow(2px 10px 10px rgba(0, 0, 0, 0.266));
}

.phone-num {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2rem;
    border-radius: 40px;
    margin-bottom: 8px;
    background-color: rgb(244, 31, 31);
    box-shadow: rgba(152, 135, 135, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(210, 183, 183, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.phone-num a {
    /* text-align: justify; */
    text-decoration: none;
    font-size: 60px;
    color: white;
    font-family: 'Secular One', sans-serif;
}

@media (max-width: 1415px) {
    .brand-icon {
        font-size: 30px;
    }
    .phone-num a {
        font-size: 30px;
    }
}
@media (max-width: 900px) {
    .brand-icon-container {
        display: none;
    }
    .brand-icon {
        font-size: 20px;
    }
    .phone-num a {
        font-size: 20px;
    }
    .social-media-container {
        max-height: auto;
        display: flex;
        justify-content: space-evenly;
    }
}
@media (max-width: 640px) {
    .social-media-container {
        display: grid;
        grid-template-areas:
        "logo number"
        "logo quote";
    }
    #logo {
        grid-area: logo;
    }
    #logo img {
        width: 180px;
    }
    .phone-num {
        padding: 1em;
        padding-left: 2em;
        padding-right: 2em;
        font-size: .25em;
    }
    .phone {
        grid-area: number;
    }
    .quote {
        grid-area: quote;
    }
}
@media (max-width: 435px) {
    #logo img {
        width: 125px;
    }
}
@media (max-width: 320px) {
    .social-media-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  #logo img {
    /* display: none; */
  }
}
</style>