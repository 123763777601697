<template>
    <div class="service-wrapper-total">
        <h1>Please click on a service to learn more!</h1>
        <div class="service-container" 
            v-for="service in services" 
            :key="service.serviceId" 
            :id="'service-container-' + service.serviceId">

            <img :src="service.img" 
                class="service-info-img" 
                :style="getStyle(service)" 
                @click="expandInfo(service.serviceId)" 
                rel="preload"/>

            <transition name="slide">
                <div class="service-info-rollout" 
                :style="getStyleContent(service)" 
                v-if="expandedIndex == service.serviceId">
                    <div class="service-content-wrapper">
                        <!-- <div class="service-content" :style="getStyleBorder(service)"> -->
                        <div class="service-content">
                            <h4>Price reflects properties less than .75 acres.</h4>
                            <ul class="price-info">
                                <li>
                                    <span :style="getTextStyle(service)">${{ service.price }}</span> per application
                                </li>
                                <span v-if="service.treatments > 0">
                                    <li>
                                        Treatments scheduled every 21-28 days from date of first application
                                    </li>
                                    <li>
                                        <span :style="getTextStyle(service)">{{ service.treatments }} total treatments</span>, mid-April to late October
                                    </li>
                                    <li class="strike">
                                        Regular annual price: ${{ calculatePrice(service)}}
                                    </li>
                                    <li>
                                        Discounted annual price: <span :style="getTextStyle(service)">${{ calculatePriceDis(service) }}</span> plus tax
                                    </li>
                                </span>
                            </ul>
                            <ul class="target-pest">
                                <p>Targets:</p>
                                <li v-for="target in service.targets"
                                :key="target">
                                    <span :style="getTextStyle(service)">
                                        {{ target }}
                                    </span>
                                </li>
                                <p>Helps with:</p>
                                <li v-for="help in service.helps"
                                :key="help">
                                    <span :style="getTextStyle(service)">
                                        {{ help }}
                                    </span>
                                </li>
                            </ul>
                            <!-- <img :src="service.infoImgUri" rel="preload"/> -->
                            <p class="service-description">
                                {{ service.desc }}
                            </p>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'our-services',
    data() {
        return {
            expandedIndex: -1
        }
    },
    computed: {
        services() {
            return this.$store.getters.getServices
        },
        getStyle() {
            return (service) => {
                if(this.expandedIndex !== service.serviceId) {
                    return {
                        color: "#fff",
                        textShadow: "1px -1px #000",
                        backgroundColor: service.color,
                        // border: "solid 3px" + service.color2 +"a2"
                    }
                } else {
                    return {
                        color: "#000",
                        textShadow: "1px -1px #fff",
                        backgroundColor: service.color2,
                        borderBottomLeftRadius:  0,
                        borderBottomRightRadius: 0,
                        // border: "outset 3px" + service.color + "a2",
                        borderBottom: 0
                    }
                }
            }
        },
        // getStyleBorder() {
        //     // return (service) => {
        //     //     return {
        //     //         border: "outset 5px" + service.color +""
        //     //     }
        //     // }
        // },
        getStyleContent() {
            return (service) => {
                if(this.expandedIndex !== service.serviceId) {
                    return {
                        backgroundColor: service.color
                    }
                } else {
                    return {
                        backgroundColor: service.color2
                    }
                }
            }
        },
        getTextStyle() {
            return(service) => {
                return {
                    fontWeight: "bold",
                    color: service.color2
                }
            }
        }
    },
    methods: {
        expandInfo(serviceId) {
            //sets expandedIndex to serviceId, -1 otherwise. Toggles expanded view
            this.expandedIndex = this.expandedIndex === serviceId ? -1 : serviceId;
            // Scroll to the top of the clicked service-container
            if (this.expandedIndex === serviceId) {
                const element = document.getElementById("service-container-" + serviceId);
                element.scrollIntoView({ behavior: "smooth", block: "nearest" }); //so sweet
            }
        },
        calculatePrice(service) {
            return service.price * service.treatments
        },
        //discount is total amount priced
        calculatePriceDis(service) {
            return service.price * service.treatments - service.price;
        }
    }
}
</script>

<style>
:root {
    --content-wrapper-height: 200px;
}

/* @media (max-width: 960px) {
    :root {
    --content-wrapper-height: 53rem;
    }
} */

.slide-enter-from,
.slide-leave-to {
    max-height: 0;
}
.slide-enter-to,
.slide-leave-from {
    max-height: var(--content-wrapper-height);
}
.slide-enter-active,
.slide-leave-active {
    transition: all .1s ease-in-out;
}

.service-wrapper-total {
    margin-top: 1.5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
}

.service-wrapper-total h1 {
    text-align: center;
    padding: 1rem;
}

.service-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.service-content-wrapper {
    transition: all .1s;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}
.service-info-rollout {
    width: 60rem;
    position: relative;
    z-index: -1;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.service-info-img {
    transition: all .3s ease;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 60rem;
}

.service-content {
    text-align: left;
    width: 95%;
    height: 95%;
    border-radius: 15px;
    margin-top: auto;
    margin-bottom: auto;
    background-color:#faf9f6;
}

.service-content img {
    max-width: 15rem;
    max-height: 15rem;
    overflow: none;
}

.service-content h4 {
    padding: .25rem;
}

.strike {
    color: rgb(65, 65, 65);
    text-decoration: line-through;
}

.price-info {
    padding-top: 5px;
    list-style-position: outside;
    list-style-type:square;
}
.price-info li {
    padding-top: 2px;
    padding-bottom: 2px;
}

.target-pest {
    padding-top: 5px;
    list-style-position: inside;
    list-style-type: circle;
}

.service-description {
    /* margin-left: 2%; */
    margin-right: 5%;
    padding: 15px;
}

.target-pest li {
    padding-top: 2px;
    padding-bottom: 2px;
}

@media (max-width: 960px) {
    .service-container {
        margin: 0;
    }
    .service-info-img {
        border-radius: 2px;
        width: 100%;
    }
    .service-info-rollout {
        width: 100%;
    }
}

/* button */
.info-button {
    margin-left: auto;
    margin-right: auto;
    top: 5%;
    left: 5%;
    right: 5%;
    bottom: 5%;
    position: absolute;

    border-width: 2px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    font-weight: bolder;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    padding: 1.5rem;
    width: fit-content;
    height: 2rem;

    transition: all .3s ease;
}
</style>
