<template>
  <div class="tile-container">
    <img v-if="imgExists" class="image" :src="require(`../../public/assets/${imgURI}`)" rel="preload"/>
    <div class="title">
      {{ title }}
    </div>
    <div class="msg">
      {{ msg }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TileImage',
  props: {
    title: String,
    msg: String,
    imgURI: String
  },
  computed: {
    imgExists() {
      return this.imgURI != null;
    }
  }
}

</script>

<style scoped>
.tile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.image {
  grid-area: img;
  width:  13.5rem;
  height: 13.5rem;
  object-fit: contain;
  margin-bottom: 1rem;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  border-radius: 80%;
}
.title, .msg {
  text-align: justify;
}
.title {
  grid-area: title;
  font-size: 1.5rem;
  font-weight: bold;
}
.msg {
  grid-area: msg;
  font-size: 1rem;
}

@media (max-width: 800px) {
  .home-content {
    background-image: url('../../public/assets/background-photo-mobile.jpg');
  }
  .title {
    padding-top: .2rem;
  grid-area: title;
  font-size: 1.25rem;
  font-weight: bold;
  }
  .msg {
    grid-area: msg;
    font-size: .9rem;
    padding-bottom: .2rem;
  }
}
</style>